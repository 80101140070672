.text-xxs-l {
  font-size: 20px;
  line-height: 20px;
  transform: scale(0.5);
  transform-origin: left;
}

.text-xxs-c {
  font-size: 20px;
  line-height: 20px;
  transform: scale(0.5);
  transform-origin: center;
}

.text-xxs-r {
  font-size: 20px;
  line-height: 20px;
  transform: scale(0.5);
  transform-origin: right;
}

.text-xxs-z {
  font-size: 20px;
  line-height: 20px;
  zoom: 0.5;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis-2 {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
}

.input-password {
  -webkit-text-security: disc;
}
