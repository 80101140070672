.fixedHeader {

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody {
    display: block;
    // 列表 有 StageProgress 组件时，不能 overflow: hidden
    // overflow: hidden;
  }
}

.table {
  // min-height: 472px;

  thead th {
    text-align: left;
    box-sizing: border-box;
  }

  tbody td {
    height: 40px;
    line-height: 40px;
    padding: 0px 16px;
    text-align: left;
  }
}

.tdHidden {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}