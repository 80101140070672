.ant-picker-focused {
  border-color: transparent;
  box-shadow: none;
  border-right-width: 2px;
  outline: 0;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #e9e9e9 !important;
  border-right-width: 2px;
}

.ant-picker-suffix {
  margin-left: 0;
}

.ant-picker-input>input {
  color: #e9e9e9;
  line-height: 14px;

  &::-webkit-input-placeholder {
    color: #4c515c;
  }
}

.ant-picker-clear {
  display: flex;
  background: #000015;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  .anticon-close-circle {
    color: #74747f;
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    border-radius: 8px;
    background: #1c1c21;

    .ant-picker-header {
      border-bottom: 1px solid #363636;
      color: #e9e9e9;

      button {
        color: #e9e9e9;
      }
    }

    .ant-picker-cell {
      color: #4c515c;
    }

    .ant-picker-cell.ant-picker-cell-in-view {
      color: #e9e9e9;
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
      background: #00a5eb;
    }

    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
      background: #00a5eb;
    }

    .ant-picker-content th {
      color: #e9e9e9;
    }

    .ant-picker-panel {
      border: none;

      .ant-picker-footer {
        border-top: 1px solid #363636;
        border-bottom: none;
      }
    }

    // 选择时分秒

    .ant-picker-datetime-panel .ant-picker-time-panel {
      border-left: 1px solid #363636;
    }

    .ant-picker-time-panel-column:not(:first-child) {
      border-left: 1px solid #363636;
    }

    .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
      color: #e9e9e9;
    }

    .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
      background: #000;
    }

    .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
      background: #00a5eb;
    }

    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      background: #121217;
      border: none;
      color: #4c515c;
    }

    .ant-btn-primary {
      color: #e9e9e9;
      border: #00a5eb;
    }

    // 选择时分秒

    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
      background: #000;
    }

    .ant-picker-week-panel-row:hover td {
      background: #000;
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background: #00a5eb;
    }

    .ant-picker-week-panel-row-selected td,
    .ant-picker-week-panel-row-selected:hover td {
      background: #00a5eb;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border: 1px solid #00a5eb;
    }

    .ant-picker-today-btn {
      color: #00a5eb;
    }
  }
}

.ant-picker-range-separator {
  padding-left: 0;

  .ant-picker-separator {
    color: #74747f;
  }
}

.ant-picker-cell-disabled {
  color: #4c515c !important;
}


.dateRange-container {
  top: 40px;
  transform: scale(0.8);
  transform-origin: 0 0;
  padding: 10px;
  background-color: #1c1c21;
  border-radius: 8px;

  .date-range {
    padding: 10;

    .rdrDateRangeWrapper {}

    .rdrDefinedRangesWrapper {
      background-color: #1c1c21;
      color: #e9e9e9;
    }

    .rdrStaticRange {
      background-color: #1c1c21;
      color: #e9e9e9;

      &:hover,
      &:focus,
      &:active {
        background-color: #0a0a10;
        color: #0a0a10;
      }
    }

    .rdrCalendarWrapper {
      background-color: #1c1c21;

      .rdrDateDisplayWrapper {
        background-color: #1c1c21;

        .rdrDateDisplay {
          .rdrDateInput {
            background-color: #1c1c21;
            color: #e9e9e9;
          }

          .rdrDateDisplayItemActive,
          .rdrDateDisplayItem {
            input {
              color: #e9e9e9;
            }
          }
        }
      }
    }

    .rdrMonthAndYearPickers {

      .rdrMonthPicker,
      .rdrYearPicker {
        select {
          color: #e9e9e9;

          option {
            color: black;
          }
        }
      }
    }

    .rdrDays {
      .rdrDayDisabled {
        background-color: #1f2027;

        .rdrDayNumber {
          span {
            color: rgba(233, 233, 233, 0.5);
          }
        }
      }
    }

    .rdrDayPassive {
      .rdrDayNumber {
        span {
          color: rgba(233, 233, 233, 0.25);
        }
      }
    }

    .rdrDayNumber {
      span {
        color: rgba(233, 233, 233, 1);
      }
    }

    .rdrInputRangeInput {
      color: #e9e9e9;
    }

    .rdrWeekDay,
    .rdrMonthName {
      color: #e9e9e9;
    }

    .rdrStartEdge,
    .rdrInRange,
    .rdrEndEdge {
      color: #00a5eb !important;
    }
  }

  .date-confirm {
    width: calc(100% - 1.666em);
  }
}