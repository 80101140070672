.ru-form-search-select {
  opacity: 1;

  #search-select-input {
    text-overflow: ellipsis;
    opacity: 1 !important;
    position: absolute;
    left: 0;
    right: 0;
  }
}

.ru-form-brand-select {
  opacity: 1;

  #brand-select-input {
    text-overflow: ellipsis;
    opacity: 1 !important;
    position: absolute;
    left: 0;
    right: 0;
  }
}
