@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

$bold: 700;
$semi-bold: 500;
$regular: 400;

$white: #ffffff;
$dark: #000000;
$yellow: #efc853;
$orange: #f58423;
$blue: #00a5eb;
$green: #00ca9f;
$red: #ef4a41;
$purple: #9747ff;
