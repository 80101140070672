body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  background-color: #000016;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(233, 233, 233, 0.5);
}

svg {
  fill: currentColor;
}

// ======================  scrollbar ========================
* {
  scrollbar-width: 0;
}

*::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 99px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.scrollbar-hide {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 0px solid transparent;
  }
}

#ContainerElementID::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

#ContainerElementID {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}

button {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: rgba(233, 233, 233, 0.5);
  font-weight: 500;
}

a {
  color: #74747f;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
  &:active,
  &:hover {
    color: #e9e9e9;
    text-decoration: none;
    outline: 0;
  }
  &:focus {
    text-decoration: none;
    outline: 0;
  }
  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}

input:-webkit-autofill,
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  transition: background-color 5000s ease-out 0.5s;
  -webkit-text-fill-color: #e9e9e9 !important; /*浏览器记住密码的字的颜色*/
  transition: background-color 5000s ease-in-out 0s; /*通过延时渲染背景色变相去除背景颜色*/
  caret-color: #e9e9e9; /*光标颜色*/

  // // 圆角input暂时无法完美修改默认填充的背景颜色
  // box-shadow: inset 0 0 0 1000px #292a33 !important;
  -webkit-text-fill-color: #e9e9e9;
  border: 2px solid #292a33 !important;
}
